<template>
	<div class="about">
		<div class="versions">
			<span>Server Version: {{ serverVersion.serverVersion }}</span>
			<span>Operator Apps Version: {{ operatorAppsVersion }}</span>
		</div>
		<div class="about-contents">
			<h1>Admin Panel Documentation</h1>
			<small>
				<strong>
					Note: Only SysAdmin, SiteAdmin, Manager, Helpdesk, or Reporter can see the User Manager View. Only SiteAdmin can see the Casino
					Administration View. For simplicity in this documentation these user types will all be referred to as Admin.
				</strong>
			</small>
			<h2>Start by Logging In</h2>
			<ol>
				<li>Log into the casino to be administered.</li>
				<li>
					Home page shows Admin's current
					<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span>.
				</li>
			</ol>
			<hr class="heading-divider" />
			<h3 class="heading">Permissions</h3>
			<p>Permissions rules are complicated. Click the button below to learn more about permissions</p>
			<button class="btn" @click="showPermissionsModal()" title="Permissions Documentation">Permissions Documentation</button>
			<hr class="heading-divider" />
			<h2>User Manager</h2>
			<p>
				Admin must have sysAdmin, siteAdmin, Manager, or HelpDesk
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this feature.
			</p>

			<ol>
				<li>User Manager starts in select user mode</li>
				<li>
					Enter the user's phone number to view Player's account
					<ul>
						<li>If Admin doesn't have Player's phone number Admin can type the area code to start a search.</li>
						<li>Admin can further filter the list with the checkboxes above the search field.</li>
					</ul>
				</li>
				<li>Click on the user below the search results to view that user.</li>
			</ol>
			<h3 class="heading">User Details <em>(tab)</em></h3>
			<ul>
				<li>Display Name</li>
				<li>User Phone Number</li>
				<li>User ID</li>
				<li>Verified User</li>
				<li>Last Login</li>
			</ul>
			<h3 class="heading">Funds &amp; Transfers <em>(tab)</em></h3>
			<p>
				If Admin has sysAdmin, or HelpDesk
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span>, Admin will see a
				Casino Selection dropdown menu.
			</p>
			<p>Select Casino from the drop down menu to see Player's balance and transactions for that casino.</p>
			<p>
				If Admin only has siteAdmin, Manager, or Reporter
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span>, there won't be a
				Casino Selection menu. This view will only show currently logged in casino.
			</p>
			<p>This view shows only Cashier transactions. MRU cash in/out not included in Cashier transactions table.</p>
			<h3 class="heading">User Actions <em>(tab)</em></h3>
			<p>
				This tab allows editing global permissions and permissions at particular casinos. This tab also allows the Admin to reset the Player's
				password with an optional note for the log.
			</p>
			<p>
				Admin must have sysAdmin
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to be able to edit
				all permissions.
			</p>
			<p>
				SiteAdmin, Manager
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> allow viewing all
				permissions but can only edit site permissions for the currently logged in casino.
			</p>
			<h3 class="heading">User Audit Logs <em>(tab)</em></h3>
			<p>
				The Rabbitsfoot server logs events of various kinds. Admin can also add arbitrary notes to a Player account which will be stored in
				this audit log.
			</p>
			<h3 class="heading">Play Activity <em>(tab)</em></h3>
			<div class="half-image-p">
				<div>
					<p class="separator-top">
						This lists all plays for the last two weeks. There is also an locked icon that indicates if there are locked funds and what
						the status of the lock is.
					</p>
					<p>Locked funds can only be unlocked by a cashier in the Cashier App.</p>
				</div>
				<figure>
					<img src="../assets/lockedFunds.jpg" alt="Locked Funds Indicator" title="Locked Funds Indicator" />
					<figcaption>Locked Funds Indicator</figcaption>
				</figure>
			</div>
			<div class="half-image-p">
				<div>
					<p class="separator-top">Click Set Filters to filter the results on this page.</p>
					<p>Admin can filter by Game Title. This is an exact match filter not a keyword search.</p>
					<p>Admin can also filter by maxium and minimum wins and by date range.</p>
				</div>
				<figure>
					<img src="../assets/FilterOptions.jpg" alt="Set Filter Options" title="Set Filter Options" />
					<figcaption>Set Filter Options</figcaption>
				</figure>
			</div>
			<div>
				<h4>Notes on the date picker.</h4>
				<p>
					As of the <em title="March 10, 2022" style="cursor: help">time of this writing</em> the date picker behaves differently on the
					various browsers.
				</p>
				<p>Chrome and Edge renders a calendar with a date and time picker <em>(pictured below).</em></p>
				<p>
					Firefox renders a calendar to pick the date but the number and AM or PM for the time must typed in
					<em>(pictured below).</em>
				</p>
				<p>
					In Firefox, if you have entered a date or a time but not both, an error message will appear when you try and submit this filter
					list. Also, the clear filter button cannot clear the dateTime input on Firefox. This is due to how Firefox handles the
					dateTime-locale input type at the time of this writing.
				</p>
				<p>
					If you wish to clear the dateTime input of any uncommited value on Firefox, click <em>Close</em> on the filter list modal dialog.
					Then when you click the <em>Set Filters</em> button again, the dateTime will be unset for any uncommited dateTime.
				</p>
				<div class="half-image-p">
					<figure>
						<img src="../assets/FilterOptionsDatePicker.jpg" alt="Chrome Date Picker" title="Chrome Date Picker" />
						<figcaption>Chrome Date Picker</figcaption>
					</figure>
					<figure>
						<img src="../assets/FilterOptionsDatePickerFF.jpg" alt="Firefox Date Picker" title="Firefox Date Picker" />
						<figcaption>Firefox Date Picker</figcaption>
					</figure>
				</div>
				<p style="separator-top-bottom">
					After setting filters, Admin can see what filter types are active at the top of the view. Hover over the filter type name and a
					tool tip appears and displays the value that is set for that filter type.
				</p>
				<figure style="width: 50%; margin: auto">
					<img src="../assets/filterToolTip.jpg" alt="Selected filter ToolTip" title="Selected filter ToolTip" />
					<figcaption>Selected filter ToolTip</figcaption>
				</figure>
			</div>
			<h3>Play Activity Detail</h3>
			<p>Clicking on any row of the game activity table will bring up the Play Activity Detail view.</p>
			<p>
				Ball draws that match to the bingo card are shown in bold in the Ball Draw section. The yellow highlighted numbers are the balls drawn
				until a pattern match.
			</p>
			<figure>
				<img src="../assets/playDetails.jpg" alt="Play Details Bingo Card" title="Play Details Bingo Card" />
				<figcaption>Play Details Bingo Card and Game Details</figcaption>
			</figure>
			<div class="half-image-p">
				<div class="separator-top">
					<p>This shows the detailed record of the game that Admin selects.</p>
					<p>This details view also shows details for each spin.</p>
					<p>
						On desktop computers you can hover over each symbol with the mouse pointer and a tooltip will show the ASCII letter, which is
						how the math system represents the symbols.
					</p>
				</div>
				<figure>
					<img src="../assets/reels.jpg" alt="Play Details Reels" title="Play Details Reels" />
					<figcaption>Play Details Reels</figcaption>
				</figure>
			</div>

			<hr class="heading-divider" />

			<h2>Casino Administration</h2>
			<p>
				Admin must have siteAdmin or sysAdmin
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this feature.
			</p>

			<h3 class="heading">Casino Basic Details <em>(tab)</em></h3>
			<p>Shows the current Casino Basic Details.</p>
			<h3 class="heading">Casino Game Configuration <em>(tab)</em></h3>
			<p>Contains Game settings configurable for each game.</p>
			<h3 class="heading">Set Game Options</h3>
			<div class="half-image-p">
				<div>
					<p class="yellow-text">Note that SiteAdmins can only access in read only mode.</p>
					<p>Click on a game row in the Paytable RTP column or the Bet Options column.</p>
					<p>This allows the setting of RTP denom amounts, number of lines, and bets per line.</p>
					<p>Setting a denom to 0 will disable that denom for that game.</p>
					<p>Unchecking all #Lines will disable the game completely.</p>
					<p>Unchecking all Bets Per Line will disable the game completely.</p>
					<p>Game can be reenabled by checking at least one #Lines and at least one Bets Per Line and at least one denom.</p>
				</div>
				<figure>
					<img src="../assets/SetGameOptions.jpg" alt="Set Game Options" title="Set Game Options" />
					<figcaption>Set Game Options</figcaption>
				</figure>
			</div>
			<div class="half-image-p">
				<div>
					<p>To simultaneously assign RTPs to multiple games, click the Bulk RTP assignment button.</p>
					<p>
						The table shown in this modal dialog lists only the games that are able to be bulk set. Any games that already have custom
						settings will not be able to be bulk set.
					</p>
					<p>The RTPs set here will be applied to all games listed in this modal dialog.</p>
					<p>
						A preview of the changes is to the right of each denom for each game in parenthesis. Yellow text indicates that the denom will
						be disabled.
					</p>
					<p>Setting a denom to 0 will disable that denom for all listed games. Yellow text indicates that the denom will be disabled.</p>
				</div>
				<figure>
					<img src="../assets/bulkRtpAssignment.jpg" alt="Set Game Options" title="Set Game Options" />
					<figcaption>Bulk RTP Assignment</figcaption>
				</figure>
			</div>

			<h3 class="heading">Set Contribution Percentage</h3>
			<div class="half-image-p">
				<div>
					<p>Note: Ways games don't use this setting.</p>
					<p>Click on a game row in the Progressives column.</p>
					<p>This shows the Progressive Family and number of tiers and the percentage each tier is set to.</p>
					<p>
						If the Progressive contribution plus RTP for a denom is equal to or exceeds 100%, a warning will show on the Casino Game
						Configuration table.
					</p>
				</div>
				<figure>
					<img src="../assets/SetContribution.jpg" alt="Set Contribution" title="Set Contribution" />
					<figcaption>Set Contribution</figcaption>
				</figure>
			</div>
			<p>
				The fields with the red exclamation points have contributions that exceed that threshold. The game table will also show warnings when
				a game is disabled or if a denom is disabled.
			</p>
			<figure>
				<img src="../assets/GameConfig.jpg" alt="Casino Game Configuration" title="Casino Game Configuration" />
				<figcaption>Casino Game Configuration</figcaption>
			</figure>
			<h3 class="heading" id="casino-details">Edit Casino Details <em>(tab)</em></h3>
			<p>Contains settings for Casino Basic Details and currency settings and enable/disable.</p>
			<p>
				Admin must have SiteAdmin or global SysAdmin
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this feature.
			</p>
			<!-- <p class="yellow-text">Note that SiteAdmins can only access in read only mode.</p> -->
			<ul>
				<li>Name <strong class="warning">(required)</strong></li>
				<li>Description (optional)</li>
				<li>Logo Url (optional)</li>
				<li>Website Url (optional)</li>
				<li>Account Limit AU <strong class="warning">(required)</strong></li>
				<li>Mru CashOut Limit AU <strong class="warning">(required)</strong></li>
				<li>Start of Business Day Hour (UTC) <strong class="warning">(required)</strong></li>
				<li>Lock Funds Threshold AU <strong class="warning">(required)</strong></li>
				<li>Admin Notes (optional, maximum of 512 characters)</li>
				<li>Account Creation Bonus Promo AU (default: 0)</li>
				<li>Playable Locations (optional)</li>
				<li>Bingo Card Policy</li>
				<li>Number of minor digits in currency unit <strong class="warning">(required)</strong></li>
				<li>Number of accounting units (AU) for each currency unit <strong class="warning">(required)</strong></li>
				<li>Currency Name Plural (optional)</li>
				<li>Currency Name Singular (optional)</li>
				<li>Currency Minor Name Plural (optional)</li>
				<li>Currency Minor Name Singular (optional)</li>
				<li>In-Game Symbol (optional)</li>
				<li>Insert Symbol In Front of Value</li>
				<li>In-Game Minor Symbol (optional)</li>
				<li>Insert Minor Symbol In Front of Value</li>
				<li>Insert space between symbol and currency</li>
				<li>ISO 4217 Code (optional)</li>
			</ul>

			<figure>
				<img src="../assets/casinoAdministration20231409.png" alt="Edit Casino Details" title="Edit Casino Details" />
				<figcaption>Edit Casino Details</figcaption>
			</figure>

			<p><strong>Playable Locations:</strong> If more than one location is listed, the locations must be separated by a comma.</p>
			<p>
				<strong>Currency Display Settings:</strong> Tells the Game Client how to display things such as the currency's name, plural name, and
				any symbols that might be relevant for showing this currency.
			</p>
			<p><strong>In-Game Symbol/In-Game Minor Symbol:</strong> Symbols displayed by the game client.</p>
			<p>
				<strong>Insert Symbol In Front of Value:</strong> If this checkbox is checked, the Game Client will insert the currency symbol at the
				front of the currency value; for example, &dollar;100.00.
			</p>
			<p>
				If you need to disable a casino, click the <em>Disable Casino</em> button. Then click <em>Ok</em> on the confirmation dialog. This
				action is immediate after clicking <em>Ok</em>. The Cashier app will still have access to funds and banks.
			</p>
			<p>
				<strong>Account Creation Bonus Promo AU:</strong> When a new player account is created, this amount of bonus promo is added to the new
				account. This is a one-time bonus.
			</p>

			<hr class="heading-divider" />
			<h2>Create/Delete Casino</h2>
			<div class="half-image-p">
				<div class="flex-column">
					<p>
						Admin must have global sysAdmin
						<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this
						feature.
					</p>
					<p>On this screen, you can create or delete casinos.</p>
					<p>
						Casinos should not be created or deleted casually. They continue to exist and take up space in the database forever, even when
						deleted.
					</p>

					<p>
						To create a new casino, click on the <em>Create Casino</em> button and the Create New Casino dialog box appears. Enter a Name
						for the casino <em>(required)</em> and a description <em>(optional)</em> and then click the <em>Create New Casino</em> button.
					</p>
					<p>
						To delete a casino, click on the Delete button and the Delete Safety dialog box appears showing important information for you
						to check before deleting a casino. When you are sure about deleting, click the <em>Delete This Casino</em> button and one
						final confirm dialog will appear.
					</p>
					<p>To undelete a casino, click on the <em>Undelete</em> button. This action is immediate.</p>
					<p>
						<strong>Note:</strong> Deleting a casino removes all site permission for that casino and disables all games for that casino.
						If that casino is undeleted, permissions would need to be re-assigned and games re-enabled.
					</p>
				</div>
				<div class="flex-column">
					<figure>
						<img src="../assets/createDeleteCasino.jpg" alt="Create/Delete Casino" title="Create/Delete Casino" />
						<figcaption>Create/Delete Casino</figcaption>
					</figure>
					<figure>
						<img src="../assets/deleteCasino.jpg" alt="Delete Safety dialog" title="Delete Safety dialog" />
						<figcaption>Delete Safety dialog</figcaption>
					</figure>
				</div>
			</div>

			<hr class="heading-divider" />
			<h2>MRU Administration</h2>
			<p>
				Admin must have global sysAdmin
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this feature.
			</p>
			<figure>
				<img src="../assets/mruAdminitration.jpg" alt="MRU Administration View" title="MRU Administration View" />
				<figcaption>MRU Administration View</figcaption>
			</figure>
			<p>This view will show a sysAdmin an at-a-glance status overview of all MRUs that are registered and have checked in.</p>
			<p>This view is not a real time view. This info is accurate for the time the MRU last checked in.</p>
			<p>A green circle icon with a ✓ (checkmark) in it means MRU has reported no problems.</p>
			<p>
				A yellow circle with ⚠ (warning) indicates <em>Warning State</em>. It could mean bill or coin dispenser is low but MRU is still
				functioning. If such a condition arises, click <em>View Details</em> to determine the problem.
			</p>
			<p>
				A red circle with ⚠ (warning) indicates <em>Attendant Needed</em>. MRU is not currently functional in this state. If such a condition
				arises, click <em>View Details</em> to determine the problem.
			</p>

			<div class="half-image-p">
				<div class="separator-top">
					<p><em>View Details</em> opens a modal dialog showing a more extensive status list.</p>
					<p>This table is not a real time table. This info is accurate for the time the MRU last checked in.</p>
					<p>This is also the modal where MRUs can be deleted. <strong class="warning">Warning: This action cannot be undone.</strong></p>
				</div>
				<figure>
					<img src="../assets/mruDetails2.jpg" alt="MRU Details" title="MRU Details" />
					<figcaption>MRU Details</figcaption>
				</figure>
			</div>
			<div class="half-image-p">
				<div class="separator-top">
					<p>Click on the MRU Title or MRU Location and the <em>Edit Mru Basic Details</em> modal dialog will open.</p>
					<p>After changing either field, a save button will appear. Click the save button and the MRU basic settings will be saved.</p>
				</div>
				<figure>
					<img src="../assets/editBasicDetails2.jpg" alt="Edit Basic Details" title="Edit Basic Details" />
					<figcaption>Edit Basic Details</figcaption>
				</figure>
			</div>
			<div class="half-image-p">
				<div class="separator-top">
					<p>Click the <em>Enabled</em> checkbox to enable or disable an MRU</p>
					<p>Disabled MRUs have an X icon as pictured in the <em>Disabled MRU</em> figure.</p>
					<p>MRUs that are enabled and with no errors will have a green circle icon with a ✓ (checkmark) in it.</p>
				</div>
				<figure>
					<img src="../assets/disabled2.jpg" alt="Disabled MRU" title="Disabled MRU" />
					<figcaption>Disabled MRU</figcaption>
				</figure>
			</div>

			<hr class="heading-divider" />
			<h2>Set Progressive Limits</h2>
			<div class="half-image-p">
				<div class="flex-column">
					<p>
						Must have global sysAdmin
						<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this
						feature.
					</p>
					<p>Setting Progressive Limits sets a cap for all progressive contributions.</p>
					<p>
						The limit set here prevents progressive pots from growing past a certain point. It does not prevent the game from paying
						progressive bases or non-progressive prizes above the number you set. If those values are unacceptable, disable the
						appropriate game titles at high denoms.
					</p>
					<p>
						If Max Enabled Progressive Total Prize is less than or equal to Max Enabled Progressive Total Prize or Max Enabled Non
						Progressive Prize, a <span class="warn">warning color</span> will show indicating some non-progressive prizes exceed the
						progressive limit.
					</p>
					<p>If game titles and/or denoms are ever re-enabled, please return here to review the limits.</p>
				</div>
				<div class="flex-column">
					<figure>
						<img src="../assets/setProgressives.png" alt="Current Progressive Limits" title="Current Progressive Limits" />
						<figcaption>Current Progressive Limits <span class="warn">(showing a warning)</span></figcaption>
					</figure>
					<figure>
						<img src="../assets/newLimit.png" alt="Set New Limits" title="Set New Limits" />
						<figcaption>Set New Limits</figcaption>
					</figure>
				</div>
			</div>
		</div>
		<transition name="slide">
			<PermissionsDocumentation v-if="showPermissions" />
		</transition>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import PermissionsDocumentation from "@/components/PermissionsDocumentation";

export default {
	name: "About",
	components: {
		PermissionsDocumentation,
	},
	props: {
		serverVersion: Object,
		isMobile: Boolean,
	},
	data() {
		return {
			showPermissions: false,
			body: document.querySelector("body"),
		};
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("closePermissionsModal", () => {
			thisInstance.showPermissions = false;
		});
		onBeforeUnmount(() => {
			this.body.onkeydown = null;
			this.eventBus.off("closePermissionsModal");
		});
	},
	mounted() {
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") this.showPermissions = false;
		};
	},

	methods: {
		showPermissionsModal() {
			this.showPermissions = this.showPermissions ? false : true;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	text-align: left;
}

h3.heading {
	background-color: #32373f;
	padding: 15px;
}

h2,
h3.heading {
	margin-top: 3em;
}

.separator-top {
	margin-top: 60px;
}

.separator-top-bottom {
	margin: 60px auto;
}

.about-contents {
	width: 90%;
	padding: 15px;
	margin: 15px auto 30px;
	background-color: rgb(0 0 0 / 60%);
	border-radius: 0.25em;
}

.half-image-p {
	display: flex;
	flex-flow: column;
	justify-content: space-around;
	align-items: center;
}

.half-image-p img {
	margin: 30px auto 0;
}

img {
	display: block;
	max-width: 100%;
	margin: auto;
}

figcaption,
.notes {
	margin-top: 15px;
	text-align: center;
}

.notes {
	background: #fff;
	color: #f00;
	padding: 15px;
	font-weight: bold;
}

.warning {
	color: #f00;
}

.half-image-p figure {
	display: flex;
	flex-direction: column;
}

.flex-column {
	display: flex;
	flex-flow: column nowrap;
}

.versions {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	margin: 5px 15px;
	text-align: center;
}

a:visited {
	color: rgb(174 86 255);
}

.warn {
	color: #ff0;
}

@media (min-width: 768px) {
	.half-image-p {
		flex-flow: row;
	}

	.half-image-p > * {
		flex: 0 1 50%;
	}
}
</style>
